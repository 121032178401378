// app/routes/__root.tsx
import {
  Outlet,
  ScrollRestoration,
  createRootRoute,
} from "@tanstack/react-router";
import { Meta, Scripts } from "@tanstack/start";
import type { ReactNode } from "react";
import {
  ClerkProvider,
  OrganizationSwitcher,
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
} from "@clerk/tanstack-start";
import styles from "~/tailwind.css?url";
import { fetchClerkAuth } from "~/lib/utils";
import React, { Suspense } from "react";

export const Route = createRootRoute({
  head: () => ({
    meta: [
      {
        charSet: "utf-8",
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1",
      },
      {
        title: "TanStack Start Starter",
      },
    ],
    links: [{ rel: "stylesheet", href: styles }],
  }),
  component: RootComponent,
  beforeLoad: async () => {
    const { user } = await fetchClerkAuth();

    return {
      user,
    };
  },
  loader: async ({ context: { user } }) => {
    return {
      user,
      ENV: {
        APP_ENV: process.env.APP_ENV,
      },
    };
  },
});

function RootComponent() {
  return (
    <ClerkProvider>
      <RootDocument>
        <Outlet />
      </RootDocument>
    </ClerkProvider>
  );
}

function RootDocument({ children }: Readonly<{ children: ReactNode }>) {
  const data = Route.useLoaderData();

  return (
    <html lang="en" className="h-full bg-gray-50">
      <head>
        <Meta />
      </head>
      <body className="h-full">
        <div className="min-h-full">
          <nav className="bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0 sm:-ml-4">
                    <a href="/">
                      <img
                        className="h-8 w-8"
                        src="/logo-light.png"
                        alt="Logo"
                      />
                    </a>
                  </div>
                  <div className="ml-10 flex items-baseline space-x-4">
                    <OrganizationSwitcher
                      hidePersonal={true}
                      afterSelectOrganizationUrl={() => "/refresh"}
                    />
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="ml-4 flex items-center md:ml-6">
                    <SignedIn>
                      <UserButton />
                    </SignedIn>
                    <SignedOut>
                      <span className="text-gray-300 px-3 py-2 rounded-md text-sm font-medium">
                        <SignInButton />
                      </span>
                    </SignedOut>
                  </div>
                </div>
              </div>
            </div>
          </nav>

          <main>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              {children}
              <script
                dangerouslySetInnerHTML={{
                  __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
                }}
              />
            </div>
          </main>
        </div>
        <ScrollRestoration />
        <Suspense>
          <TanStackRouterDevtools position="bottom-right" />
        </Suspense>
        <Scripts />
      </body>
    </html>
  );
}

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        }))
      );
