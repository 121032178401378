/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as ThankYouImport } from './routes/thank-you'
import { Route as RefreshImport } from './routes/refresh'
import { Route as IndexImport } from './routes/index'
import { Route as SignInSplatImport } from './routes/sign-in.$'
import { Route as ProjectsProjectIdImport } from './routes/projects.$projectId'
import { Route as IssuesProjectIdImport } from './routes/issues.$projectId'
import { Route as IssueProjectIdIssueIdImport } from './routes/issue.$projectId.$issueId'

// Create/Update Routes

const ThankYouRoute = ThankYouImport.update({
  id: '/thank-you',
  path: '/thank-you',
  getParentRoute: () => rootRoute,
} as any)

const RefreshRoute = RefreshImport.update({
  id: '/refresh',
  path: '/refresh',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const SignInSplatRoute = SignInSplatImport.update({
  id: '/sign-in/$',
  path: '/sign-in/$',
  getParentRoute: () => rootRoute,
} as any)

const ProjectsProjectIdRoute = ProjectsProjectIdImport.update({
  id: '/projects/$projectId',
  path: '/projects/$projectId',
  getParentRoute: () => rootRoute,
} as any)

const IssuesProjectIdRoute = IssuesProjectIdImport.update({
  id: '/issues/$projectId',
  path: '/issues/$projectId',
  getParentRoute: () => rootRoute,
} as any)

const IssueProjectIdIssueIdRoute = IssueProjectIdIssueIdImport.update({
  id: '/issue/$projectId/$issueId',
  path: '/issue/$projectId/$issueId',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/refresh': {
      id: '/refresh'
      path: '/refresh'
      fullPath: '/refresh'
      preLoaderRoute: typeof RefreshImport
      parentRoute: typeof rootRoute
    }
    '/thank-you': {
      id: '/thank-you'
      path: '/thank-you'
      fullPath: '/thank-you'
      preLoaderRoute: typeof ThankYouImport
      parentRoute: typeof rootRoute
    }
    '/issues/$projectId': {
      id: '/issues/$projectId'
      path: '/issues/$projectId'
      fullPath: '/issues/$projectId'
      preLoaderRoute: typeof IssuesProjectIdImport
      parentRoute: typeof rootRoute
    }
    '/projects/$projectId': {
      id: '/projects/$projectId'
      path: '/projects/$projectId'
      fullPath: '/projects/$projectId'
      preLoaderRoute: typeof ProjectsProjectIdImport
      parentRoute: typeof rootRoute
    }
    '/sign-in/$': {
      id: '/sign-in/$'
      path: '/sign-in/$'
      fullPath: '/sign-in/$'
      preLoaderRoute: typeof SignInSplatImport
      parentRoute: typeof rootRoute
    }
    '/issue/$projectId/$issueId': {
      id: '/issue/$projectId/$issueId'
      path: '/issue/$projectId/$issueId'
      fullPath: '/issue/$projectId/$issueId'
      preLoaderRoute: typeof IssueProjectIdIssueIdImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/refresh': typeof RefreshRoute
  '/thank-you': typeof ThankYouRoute
  '/issues/$projectId': typeof IssuesProjectIdRoute
  '/projects/$projectId': typeof ProjectsProjectIdRoute
  '/sign-in/$': typeof SignInSplatRoute
  '/issue/$projectId/$issueId': typeof IssueProjectIdIssueIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/refresh': typeof RefreshRoute
  '/thank-you': typeof ThankYouRoute
  '/issues/$projectId': typeof IssuesProjectIdRoute
  '/projects/$projectId': typeof ProjectsProjectIdRoute
  '/sign-in/$': typeof SignInSplatRoute
  '/issue/$projectId/$issueId': typeof IssueProjectIdIssueIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/refresh': typeof RefreshRoute
  '/thank-you': typeof ThankYouRoute
  '/issues/$projectId': typeof IssuesProjectIdRoute
  '/projects/$projectId': typeof ProjectsProjectIdRoute
  '/sign-in/$': typeof SignInSplatRoute
  '/issue/$projectId/$issueId': typeof IssueProjectIdIssueIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/refresh'
    | '/thank-you'
    | '/issues/$projectId'
    | '/projects/$projectId'
    | '/sign-in/$'
    | '/issue/$projectId/$issueId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/refresh'
    | '/thank-you'
    | '/issues/$projectId'
    | '/projects/$projectId'
    | '/sign-in/$'
    | '/issue/$projectId/$issueId'
  id:
    | '__root__'
    | '/'
    | '/refresh'
    | '/thank-you'
    | '/issues/$projectId'
    | '/projects/$projectId'
    | '/sign-in/$'
    | '/issue/$projectId/$issueId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  RefreshRoute: typeof RefreshRoute
  ThankYouRoute: typeof ThankYouRoute
  IssuesProjectIdRoute: typeof IssuesProjectIdRoute
  ProjectsProjectIdRoute: typeof ProjectsProjectIdRoute
  SignInSplatRoute: typeof SignInSplatRoute
  IssueProjectIdIssueIdRoute: typeof IssueProjectIdIssueIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  RefreshRoute: RefreshRoute,
  ThankYouRoute: ThankYouRoute,
  IssuesProjectIdRoute: IssuesProjectIdRoute,
  ProjectsProjectIdRoute: ProjectsProjectIdRoute,
  SignInSplatRoute: SignInSplatRoute,
  IssueProjectIdIssueIdRoute: IssueProjectIdIssueIdRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/refresh",
        "/thank-you",
        "/issues/$projectId",
        "/projects/$projectId",
        "/sign-in/$",
        "/issue/$projectId/$issueId"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/refresh": {
      "filePath": "refresh.tsx"
    },
    "/thank-you": {
      "filePath": "thank-you.tsx"
    },
    "/issues/$projectId": {
      "filePath": "issues.$projectId.tsx"
    },
    "/projects/$projectId": {
      "filePath": "projects.$projectId.tsx"
    },
    "/sign-in/$": {
      "filePath": "sign-in.$.tsx"
    },
    "/issue/$projectId/$issueId": {
      "filePath": "issue.$projectId.$issueId.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
